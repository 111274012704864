<!--
    自定义img组件，用于懒加载，图片压缩等处理
    @Author：史芸瑞
    @Date：2020-8-4
    @desc：
 -->
<template>

  <!-- <div class="myImg" v-lazy:background-image="src" :key="src"></div> -->

  <img id="myImg" class="myImg" v-lazy="src" :key="src"/>
    
</template>
<script>
// import store from "@/store/store";

export default {
  name: "myImgLazy",
  props: {
    src: '',
    needLazyLoad: true,//是否需要懒加载
    needCompress: false,//是否需要图片压缩
  },
};
</script>
<style lang="scss" scoped>
  .myImg{
    background: url('~@/assets/img/img_lazy_bg.png') center center no-repeat ;
    background-size: cover;
  }
</style>