<!--
 * @Author: yangjinlai
 * @Date: 20220607
 * @Description: 发行人详情
-->
<template>
<div>
  <div class="content publisherDetailWrap">

      <div class="publisherDetail w1200" ref="publisherDetail">

          <div class="detailTop">
              <div class="topContent">

                  <div class="left">
                      <!-- <myImgBgLazy class="logo" :src="detailData.logo"></myImgBgLazy> -->
                       <img class="logo" :src="detailData.logo" alt=""> 
                  </div>

                  <div class="topRight">
                      <p class="name">{{detailData.issuerName}}</p>

                      <div class="tagList" >
                          <span class="tag" v-for="(el, i) in detailData.tagList" :key="i" >{{el}}</span>
                      </div>
                  </div>
              </div>

              <p class="text">{{detailData.briefIntroduction}}</p>

              <div class="orgIntro">
                  <div class="contentDiv" :introduction="detailData.introduction">
                      {{detailData.introduction}}
                  </div>
                  <!-- <div class="contentHide">
                      <span class="txt">机构简介：</span>{{detailData.introduction}}
                  </div> -->
                  <!-- <span v-if="showBtn" class="btn" @click="showMore">更多</span> -->
              </div>
          </div>

          <!-- 视频直播 -->
           <div class="sectionWrap live-wrap" v-if="detailData.videoList && detailData.videoList.length">
              <!-- <div class="bg-color"></div> -->
              <div class="tit-box">
                  <!-- <div class="tit"> -->
                    <div class="title">视频直播</div>
                    <div class="smallTitle">Video</div>
                  <!-- </div> -->
              </div> 
              <i></i>
              <div class="videoWrap">
                <div class="swiper-container czp">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in detailData.videoList" :key="index">
                        <div class="videoDiv">
                            <video width="380" height="215" :id="'player_'+index" preload="auto" playsinline webkit-playsinline ></video>
                            <div class="live-tit">{{item.title}}</div>
                        </div>
                    </div>
                  </div> 
                  <!-- <div class="swiper-pagination"></div> -->
                </div>
              </div>



              <!-- <van-swipe class="live-box" autoplay="false" indicator-color="white" :style="'padding-bottom:'+ (detailData.videoList.length == 1 ? '.0' : '.4rem') ">
                  <van-swipe-item v-for="(item,index) in detailData.videoList" :key="index" indicator-color="#ff0000">
                      <li class="live-item width100" >

                          <video width="375" height="199" :id="'player_'+index" preload="auto" playsinline webkit-playsinline ></video>

                          <div class="live-tit">{{item.title}}</div>
                          <div class="live-time" :style="'margin-bottom:'+ (detailData.videoList.length == 1 ? '.3rem' : '.5rem') ">{{ item.publishTime }}</div>

                      </li>
                  </van-swipe-item>
              </van-swipe> -->
           </div> 
          <!-- 视频直播end -->

          <!-- 精选资讯 -->
           <div class="live-wrap" v-if="detailData.newsList && detailData.newsList.length > 0">
              <div class="bg-color"></div>
              <div class="tit-box">
                  <div class="tit">精选资讯</div>
                   <div class="news">News</div>
              </div>
              <i></i>
              <div class="news-wrap">
                  <ul class="card">
                      <li class="news-list" v-for="(item,i) in detailData.newsList" :key="i" @click="toNewsDetail(item.newsId, item.newsTitle)">
                        <!-- <img :src="item.coverUrl" alt="" class="img"> -->
                        <myImgLazy :src="item.coverUrl" class="img"></myImgLazy>
                        <div class="news-content">
                            <p class="newsTitle">{{item.newsTitle}}</p>
                            <span class="newsTime">{{item.publishTime | FILTER_FORMAT_TIME( "YYYY-MM-DD" )}}</span>
                        </div>
                        
                      </li>     
                         
                  </ul>
              </div>    
          </div> 
          <!-- 精选资讯end -->

      </div>
  </div>
  <div style="margin-top:30px">
      <my-foot @changePage="changePage" id="anchor_3"/>
  </div>
 </div>
</template>

<script>

import '../../assets/css/publisher/detail.scss';
import myFoot from '@/components/myFoot/index.vue'
import myImgLazy from '@/components/myImgLazy/index.vue'
import { getIssuerDetail } from '@/web/publisher'

import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'

import '@public/core/vueVideo/video.css'

// import { setTimeout, clearTimeout } from 'timers';
export default {
  name: 'index',
  data() {
    return {
      issuerId: this.$route.query.issuerId || "",
      detailData: {},

      playerList: [],
    //   issuerList:[],
    //   iconList:[]
    }
  },
  components: {
    myFoot,
    Swiper,
    myImgLazy,
  },
  created() {
    
    
  }, 
  mounted(){

    let _this = this;

    //当前导航定位在发行人
    this.$store.commit('changePage', 2) 

    // 创建script标签，引入外部文件
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '/core/vueVideo/video.js'
    document.querySelector('.publisherDetailWrap').appendChild(script)

    getIssuerDetail({ issuerId: this.issuerId }).then((res) => {
      this.detailData = res

    // 模拟多个视频
    //   this.detailData.videoList = this.detailData.videoList.concat( this.detailData.videoList )

      this.$nextTick(function(){
        this.detailData.videoList.forEach( (item, index) => {
              let player = TCPlayer('player_'+index, {
                  controlBar: {
                      volumePanel: false,
                      fullscreenToggle: false,
                      playbackRateMenuButton: false,
                      QualitySwitcherMenuButton: false,
                      progressControl: false
                  },
                  // plugins: {
                  //     ProgressMarker: true, //控制进度条显示
                  // },

              }); // player-container-id 为播放器容器 ID，必须与 html 中一致
              player.src(item.videoUrl);
              player.poster(item.coverUrl);
              //添加到数组中保存起来
             _this.playerList.push( player )

              player.on('play', function(event){
                    //一个播放时，暂停其他所有
                    _this.playerList.forEach(( el, i ) => {
                        
                        if( el.id_ != player.id_ ){
                            el.pause()
                        }
                    })
              })
          })

            if( this.detailData.videoList.length > 3){
                var mySwiper = new Swiper('.swiper-container',{
                    direction: 'horizontal', 
                    slidesPerView : 'auto',
                    // loop: true,
                    // slidesOffsetBefore:30,

                    // pagination : '.swiper-pagination',
                    // paginationClickable: true,

                    observer:true,
                    observeParents:true,
                })
            }
            
        })
    })
  },

  methods: {

    onSwiper(){

    },

    onSlideChange(){

    },

    changePage(i) {
      this.$store.commit('changePage', i) 
    },
    // 获取高度
    // getHeight(name,id){
    //   let time = setTimeout(()=>{
    //     if(document.getElementById(name+id).clientHeight>60){//如果标签大于60高度，说明是两行标签
    //       this.iconList[id]=2;//两行标签，下面的内容就保留一行点点点
    //     }else{
    //       this.iconList[id]=1//一行标签，下面的内容就保留两行点点点
    //     };
    //     clearTimeout(time);
    //   },1000)
    // }
    getprolist(){

    },
    toNewsDetail(id){
        this.$router.push({path: '/detaillist', query: { newsId:  id } })
    //   this.$router.push({name:"topublisherDetail",query:{newsId:id}})
      // this.$router.push("/publisherDetail/gopublisherDetail/"+id)
    }

  },
}
</script>


