import request from '@/utils/request'


export function getIssuerDetail(data = {}) {
  return request({
    url: '/mcontent/frontend/issuer/detail',
    method: 'post',
    data
  })
}

